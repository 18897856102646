<template>
  <main>
    <transition
      name="fade"
      mode="out-in"
    >
      <div v-if="itemsExist">
        <!-- 選択したコンシェルジュの提案商品があった場合 -->
        <div v-if="currentItemExist">
          <section class="result_itemSuggestion_tittle_container">
            <div class="result_itemSuggestion_tittle_inner">
              <h1>あなたにオススメの商品はこちらです。</h1>
              <div class="concierge_info">
                <img
                  :src="
                    ROOT_IMAGE_URL
                      + '/face-icon/'
                      + lower_concierge_name
                      + '.png'
                  "
                >
                <div class="concierge_profile">
                  <span>
                    {{ title }}
                  </span>
                  <h2>
                    {{ concierge_name }}
                  </h2>
                  <router-link
                    :to="{
                      name: 'Concierges',
                      params: {
                        incomingGenre: 'skincare',
                        incomingConcierge: lower_concierge_name,
                      },
                    }"
                    class="profile_btn"
                  >
                    プロフィールを見る
                  </router-link>
                </div>
              </div>
            </div>
          </section>
          <section class="suggested_item">
            <div class="item_category_answerConditions_container">
              <div class="item_category_answerConditions">
                <p class="item_category">
                  {{ genre }}
                  &nbsp;|&nbsp;
                  <span>
                    {{ category }}
                  </span>
                </p>
                <div class="mappedAnswers">
                  <p
                    v-for="answer in mappedAnswers"
                    :key="answer"
                  >
                    {{ answer }}
                  </p>
                </div>
              </div>
            </div>

            <div class="suggested_item_info">
              <item-list
                genre="skin-care"
                :category="category"
                :items="items"
              />
            </div>
          </section>
          <section class="guiding_section_container">
            <div class="guiding_section_inner">
              <div class="guiding_section_toChatbot">
                <router-link to="/flow/item-suggestions">
                  もう一度コンシェルジュからオススメ商品の提案を受ける
                </router-link>
              </div>
            </div>
          </section>
        </div>
        <!-- 選択したコンシェルジュの商品がなく、他のコンシェルジュの提案商品があった場合 -->
        <div v-else>
          <section class="result_itemSuggestion_tittle_container">
            <div class="result_itemSuggestion_tittle_inner">
              <h1>
                ご相談いただいたコンシェルジュのオススメ商品がありません。他のコンシェルジュのオススメ商品をご提案いたします。
              </h1>
              <div class="concierge_info">
                <img
                  :src="
                    ROOT_IMAGE_URL
                      + '/face-icon/'
                      + lower_concierge_name
                      + '.png'
                  "
                >
                <div class="concierge_profile">
                  <span>
                    {{ title }}
                  </span>
                  <h2>
                    {{ concierge_name }}
                  </h2>
                  <router-link
                    :to="{
                      name: 'Concierges',
                      params: {
                        incomingGenre: 'skincare',
                        incomingConcierge: lower_concierge_name,
                      },
                    }"
                  >
                    プロフィールを見る
                  </router-link>
                </div>
              </div>
            </div>
          </section>
          <section class="suggested_item">
            <div class="item_category_answerConditions_container">
              <div class="item_category_answerConditions">
                <p class="item_category">
                  {{ genre }}
                  &nbsp;|&nbsp;
                  <span>
                    {{ category }}
                  </span>
                </p>
                <div class="mappedAnswers">
                  <p
                    v-for="answer in mappedAnswers"
                    :key="answer"
                  >
                    {{ answer }}
                  </p>
                </div>
              </div>
            </div>
            <div class="suggested_item_info">
              <item-list
                genre="skin-care"
                :category="category"
                :items="items"
              />
            </div>
          </section>
          <section class="guiding_section_container">
            <div class="guiding_section_inner">
              <div class="guiding_section_toChatbot">
                <router-link to="/flow/item-suggestions">
                  もう一度コンシェルジュからオススメ商品の提案を受ける
                </router-link>
              </div>
            </div>
          </section>
        </div>
      </div>
      <div v-else-if="isBeforeMounted">
        <section class="firstTouch">
          <div class="firstTouch_container">
            <img src="@/assets/about/eye-catching-drop.png">
          </div>
        </section>
      </div>
      <!-- 提案商品が無い場合 -->
      <div
        v-else
        class="no_item"
      >
        <section class="not-found_container">
          <p class="not-found">
            大変申し訳ございません。<br>
            お客様の条件にあうオススメ商品がありませんでした。<br>
            条件を変えて再度、コンシェルジュにご相談ください。
          </p>
        </section>
        <section class="item_category_answerConditions_container">
          <div class="item_category_answerConditions">
            <p class="item_category">
              {{ genre }}
              &nbsp;|&nbsp;
              <span>
                {{ category }}
              </span>
            </p>
            <div class="mappedAnswers">
              <p
                v-for="answer in mappedAnswers"
                :key="answer"
              >
                {{ answer }}
              </p>
            </div>
          </div>
        </section>
        <section class="guiding_section_container">
          <div class="guiding_section_inner">
            <div class="guiding_section_toChatbot">
              <router-link to="/flow/item-suggestions">
                もう一度コンシェルジュからオススメ商品の提案を受ける
              </router-link>
            </div>
          </div>
        </section>
      </div>
    </transition>
  </main>
</template>
<script>
import ItemList from "../../components/ItemList.vue";
import axios from "axios";
import liff from "@line/liff";
const answerMapper = require("../../data/answerMapper");
const concierges = require("../../data/concierges");

export default {
  name: "SkincareItemResult",
  components: {
    ItemList,
  },
  props: {
    id_token: {
      type: String,
      default: "",
    },
    result_set: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      // 回答マッピング用データ
      answerMapper,
      // コンシェルジュ一覧
      concierges,
      title: "",
      // 画像のルートURL
      ROOT_IMAGE_URL: process.env.VUE_APP_IMAGE_ROOT,
      // メジャー情報
      res_data: {},
      concierge_name: "",
      lower_concierge_name: "ayako",
      genre: "",
      category: "",
      mappedAnswers: [],
      // 商品個別情報
      items: [],
      other_concierge_name: "",
      has_main_concierge: 0,
      isCommentOpen: false,
      isBeforeMounted: true,
    };
  },
  computed: {
    itemsExist() {
      return this.items.length > 0;
    },
    currentItemExist() {
      return this.has_main_concierge;
    },
    currentItem() {
      return this.items[this.currentPaginateNumber];
    },
    a8_affiliate_img() {
      // URLを取得
      if ("rakuten" in this.currentItem.affiliate_links) {
        var url = new URL(this.currentItem.affiliate_links.rakuten);
        // URLSearchParamsオブジェクトを取得
        var params = url.searchParams;
        return (
          "https://www15.a8.net/0.gif?a8mat=" +
          params.get("a8mat").split(" ").join("+")
        );
      } else {
        return "";
      }
    },
    moshimo_affiliate_img() {
      // URLを取得
      if ("amazon" in this.currentItem.affiliate_links) {
        var url = new URL(this.currentItem.affiliate_links.amazon);
        // URLSearchParamsオブジェクトを取得
        var params = url.searchParams;
        return (
          "https://i.moshimo.com/af/i/impression?a_id=" +
          params.get("a_id") +
          "&p_id=" +
          params.get("p_id") +
          "&pc_id=" +
          params.get("pc_id") +
          "&pl_id=" +
          params.get("pl_id")
        );
      } else {
        return "";
      }
    },
  },
  mounted() {
    const target_url = process.env.VUE_APP_RESULT_URL + "item-suggestions";
    axios
      .post(target_url, {
        liff_id: process.env.VUE_APP_LIFF_ID,
        command: "get_result_skincare_products",
        id_token: this.id_token ? this.id_token : liff.getIDToken(),
        result_set: this.result_set,
      })
      .then((res) => {
        const res_data = res.data;
        this.res_data = res_data;
        if (res_data.main_concierge == "ぺき") {
          this.concierge_name = "ペキ";
          this.lower_concierge_name = "peki";
        } else {
          this.concierge_name = res_data.main_concierge;
          this.lower_concierge_name = res_data.main_concierge.toLowerCase();
        }
        this.title = this.concierges.find(
          (concierge) => concierge.name_lower === this.lower_concierge_name
        ).title;
        this.mappedAnswers = this.getMappedAnswers(
          "skincare",
          res_data.result_set
        );
        this.genre = res_data.genre;
        this.category = res_data.category;
        // 商品の最大表示数を5に設定
        this.items = res_data.Items.slice(0, 5);
        // 最初の商品がメインコンシェルジュの商品であるか
        this.has_main_concierge = res_data.Items.length > 0 ? res_data.Items[0].has_main_concierge : false;
        this.other_concierge_name = res_data.main_concierge.toLowerCase();
        this.$gtag.event('view_item_list', {
          item_list_name: "Skincare Result",
          items: this.convertItemList2GA4(this.items)})
        this.isBeforeMounted = false;
      })
      .catch((err) => {
        console.log("SkincareItemResult", err.code, err.message);
      });
  },
  methods: {
    getMappedAnswers(genre, result_set) {
      let mappedAnswers = [];
      for (let qa of Object.entries(result_set)) {
        if (
          qa[0] in this.answerMapper[genre] &&
          qa[1] in this.answerMapper[genre][qa[0]]
        ) {
          if (this.answerMapper[genre][qa[0]][String(qa[1])] != "")
            mappedAnswers.push(this.answerMapper[genre][qa[0]][String(qa[1])]);
        }
      }
      return mappedAnswers;
    },
    convertNameToRoman(name) {
      if (name == "ぺき" || name == "ペキ") {
        return "peki";
      }
      return name.toLowerCase();
    },
    isAffiliateUrlBlank(affiliateUrls) {
      if (
        (!("amazon" in affiliateUrls) || affiliateUrls.amazon == "") &&
        (!("rakuten" in affiliateUrls) || affiliateUrls.rakuten == "")
      ) {
        return true;
      } else {
        return false;
      }
    },
    selectItemGA4(shopname) {
      var item = this.convertItemList2GA4([this.currentItem]);
      item[0].promotion_name = shopname;
      item[0].index = this.currentPaginateNumber;
      this.$gtag.event("select_item", {
        item_list_name: "Skincare Items",
        items: item,
      });
    },
    convertItem2Ga4(item) {
      return {
        currency: "JPY",
        value:
          item.affiliate_links.amazon != "" || item.affiliate_links != ""
            ? item.official_price
            : 0,
        items: [
          {
            item_id: item.item_id,
            item_name: item.item_name,
            item_brand: item.brand_name,
            item_category: this.genre,
            item_category2: this.cagtegory,
            affiliation: item.has_main_concierge
              ? this.concierge_name
              : "(" + item.other_concierges.join(",") + ")",
          },
        ],
      };
    },
    convertItemList2GA4(items) {
      return items.map((item, idx) => {
        return {
          item_id: item.item_id,
          item_name: item.item_name,
          item_brand: item.brand_name,
          item_category: this.genre,
          item_category2: this.category,
          index: idx,
          affiliation: item.has_main_concierge
            ? this.concierge_name
            : "(" + item.other_concierges.join(",") + ")",
        };
      });
    },
  },
};
</script>
<style scoped>
/* メイン */
main {
  font-family: "YuGothic";
  font-size: 14px;
}
h1 {
  font-size: 24px;
  font-weight: bold;
}
h2 {
  font-size: 22px;
  font-weight: bold;
}
h3 {
  font-size: 20px;
}
a {
  text-decoration: none;
  color: #777777;
}
.result_itemSuggestion_tittle {
  background-color: #ffffff;
  padding: 0 40px;
}
.result_itemSuggestion_tittle_container {
  padding: 0 40px;
}
.result_itemSuggestion_tittle_inner {
  padding: 50px 0;
}
.result_itemSuggestion_tittle_container span {
  font-size: 24px;
}
.result_itemSuggestion_tittle_container span {
  font-size: 24px;
}
.concierge_info {
  display: flex;
  align-items: center;
  padding: 20px 0 0 0;
}
.concierge_info img {
  width: 90px;
  border-radius: 50%;
  border: #f29f8f solid 1px;
}
.concierge_profile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 20px;
}
.concierge_profile span {
  font-size: 14px;
}
.concierge_profile h2 {
  color: #555555;
  padding: 2px;
}
.concierge_profile a {
  padding: 11px 14px;
  background-color: #fadad2;
  font-size: 16px;
}
.profile_btn {
  padding: 11px 14px;
  background-color: #fadad2;
  margin-top: 7px;
}
.item_category_answerConditions_container {
  background-color: #f5eae7;
  padding: 0 40px;
}
.item_category_answerConditions {
  padding: 50px 0 17px;
}
.item_category {
  display: inline-block;
  font-size: 16px;
  padding-bottom: 10px;
}
.mappedAnswers {
  display: flex;
  flex-wrap: wrap;
}
.mappedAnswers p {
  padding-right: 10px;
  padding-bottom: 3px;
  font-size: 12px;
}
.item_img {
  width: 100%;
  object-fit: cover;
  padding: 30px 0;
}
.item_img_container {
  position: relative;
}
.item_name {
  padding-bottom: 20px;
}
.item_purchase {
  padding-bottom: 12px;
  /* display: flex;
  justify-content: space-between;
  align-items: center; */
  text-align: center;
}
.amazon,
.rakuten {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-bottom: 5px;
}
.amazon img,
.rakuten img {
  width: 40px;
}
.item_price {
  margin-left: 15px;
}
.purchase_btn {
  margin-left: 15px;
}
.item_price {
  font-family: "Brandon Grotesque";
  font-size: 24px;
}
.item_purchase a {
  font-size: 16px;
  font-weight: bold;
  padding: 10px 18px;
  color: #ffffff;
  background-color: #f29f8f;
  display: inline-block;
}
/* .item_price_btn {
  text-align: center;
}
.item_price_btn p {
  display: inline-block;
  padding: 11px 13px;
  border: #f29f8f solid 1px;
} */
.item_comment_container {
  background-color: #ffffff;
}
.item_comment_inner {
  padding: 50px 30px;
}
.item_comment_title {
  text-align: center;
}
.item_comment_title p {
  font-size: 17px;
}
.item_comment {
  padding: 30px 0 20px 0;
}
.item_comment_btn {
  text-align: center;
}
.item_comment_btn p {
  display: inline-block;
  padding: 11px 17px;
  border: #f29f8f solid 1px;
}
.concierges_info_container p {
  font-size: 18px;
  color: #222222;
  padding: 50px 0 20px 0;
}
.concierges_info_inner {
  display: flex;
}
.concierges_info_inner p {
  font-size: 18px;
  padding-bottom: 20px;
}
.concierge_other {
  width: 100%;
  padding-right: 30px;
}
.concierge_other > * + * {
  margin-left: 20px;
}
.concierge_other img {
  width: 60px;
  border-radius: 50%;
}
.concierge_other p {
  font-size: 12px;
  padding: 5px 0 0 0;
  margin: 0;
  text-align: center;
  color: #707070;
}
.guiding_section_inner {
  padding: 100px 40px 0;
  text-align: center;
  background-color: #f5eae7;
}
.guiding_arrow {
  width: 26px;
  padding-bottom: 40px;
}
.dermatologist_inCarge_inner img {
  padding: 10px 0;
}
.guiding_section_inner {
  padding: 100px 40px 0;
  text-align: center;
  background-color: #f5eae7;
}
img,
embed,
object,
iframe {
  vertical-align: bottom;
}
a {
  background-color: transparent;
  text-decoration: none;
  color: inherit;
}
router-link {
  background-color: transparent;
  text-decoration: none;
  color: inherit;
}
.guiding_section_toChatbot a {
  display: block;
  padding: 20px 30px;
  font-size: 18px;
  color: #444444;
  background-color: #fadad2;
  font-weight: bold;
}
.guiding_section_toChatbot {
  padding-bottom: 50px;
}
.guiding_section_toChatbot p {
  display: block;
  padding-bottom: 20px;
  font-size: 16px;
}
.not-found_container {
  padding: 50px 0;
}
.not-found {
  line-height: 1.5;
  font-size: 24px;
  font-weight: bold;
  padding: 0 40px;
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s ease;
}

.fade-enter-from, .fade-leave-to {
  opacity: 0;
}
.firstTouch{
  background-color: #ffffff;
  padding: 0 40px;
}
.firstTouch_container{
  padding: 500px 0 50px 0;
  text-align: right;
}
.firstTouch_container h1{
  font-size: 23pxß;
  display: inline-block;
  text-align: center;
  padding-bottom: 45px;
  line-height: 1.7;
}
.firstTouch_container img{
  width: 170px;
  padding: 0 15px 50px 0;
  object-fit: cover;
}
</style>
